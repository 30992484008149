<template>
    <b-modal size="lg" id="modal-cart" centered hide-footer title="Detail Pesanan">
        <div class="text-dark text-darken-1 fw-bold-500" v-if="cartDetail && cartDetail.customer">
            <b-row align-h="center">
                <b-col cols="11">
                    <div>
                        Nama Pelanggan: {{ cartDetail.customer.name }}
                    </div>
                    <div class="d-flex">
                        Alamat Pelanggan:
                        <div v-html="(cartDetail.customer.address)"></div>
                    </div>
                    <div>
                        No. Telepon Pelanggan: {{ cartDetail.customer.phone_number }}
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="mt-1 text-dark text-darken-1 fw-bold-800">
            <!-- <b-table responsive="sm" :items="cartDetail.items" :fields="fields" /> -->
            <b-row align-h="center">
                <b-col cols="5">
                    Nama Produk
                </b-col>
                <b-col cols="2" class="text-center">
                    Qty
                </b-col>
                <b-col cols="2">
                    Harga
                </b-col>
                <b-col cols="2">
                    Jumlah
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col cols="11">
                    <hr class="my-25" />
                </b-col>
            </b-row>
        </div>
        <div class="text-dark text-darken-1 fw-bold-500 mb-2">
            <!-- <b-table responsive="sm" :items="cartDetail.items" :fields="fields" /> -->
            <b-row align-h="center" v-for="item in cartDetail.items" :key="item.uuid" class="mb-25">
                <b-col cols="5">
                    {{ item.product.name }}
                </b-col>
                <b-col cols="2" class="text-center">
                    {{ item.qty }}
                </b-col>
                <b-col cols="2">
                    {{ item.sub_price | formatAmount }}
                </b-col>
                <b-col cols="2">
                    {{ item.total | formatAmount }}
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col cols="11">
                    <hr class="my-25" />
                </b-col>
            </b-row>
            <b-row align-h="end" class="mt-1 fw-bold-800 mr-2">
                <b-col cols="7"></b-col>
                <b-col cols="2">Total:</b-col>
                <b-col cols="2">{{ cartDetail.total | formatAmount }}</b-col>
            </b-row>
            <div class="ml-2 mt-2">*Belum Termasuk Biaya Tambahan</div>
        </div>
    </b-modal>
</template>

<script>
import {
    BRow, BCol, BModal, BButton, BContainer, BImg, BTable
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BModal,
        BButton,
        BImg,
        BTable
    },
    data() {
        return {
            fields: [
                { key: 'product.name', label: 'Nama Produk' },
                { key: 'qty', label: 'Qty' }
            ],
            bordered: true,
            headVariant: null
        }
    },
    props: {
        cartDetail: {
            type: Object,
        },
    },
}
</script>